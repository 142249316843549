<template>
  <div class="side-filter-card card">
    <div class="card-header">
      <h2 class="mb-0">
        <b-button v-b-toggle="'sliderFilter'" class="btn btn-link lesiurtabFilter-header" variant="link">
          {{ $t("search-result." + item.title) }}
        </b-button>
      </h2>
    </div>
    <b-collapse id="sliderFilter" v-model="isVisible">
      <b-card>
        <div class="card-body">
          <vue-slider
            v-model="value"
            :min="min"
            :max="max"
            :marks="marks"
            :hideLabel="true"
            :included="true"
            :contained="true"
            tooltip="always"
            @change="change"
          ></vue-slider>
          <p>
            {{ $t("search-result.from") }} ${{ value[0] }} {{ $t("search-result.to") }}
            ${{ value[1] }}
          </p>
          <!-- :clickable="false" -->
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';

import { BButton, BCollapse, VBToggle, BCard } from 'bootstrap-vue';

export default {
  name: 'SidebarCollapseItem',
  components: {
    BButton,
    BCollapse,
    BCard,
    VueSlider,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: true,
      value: this.item.value,
      min: this.item.min,
      max: this.item.max,
      marks: this.item.marks,
    };
  },
  watch: {
    item(newItem) {
      this.min = newItem.min;
      this.max = newItem.max;
      this.marks = newItem.marks;
      this.$nextTick(() => {
        this.value = newItem.value;
      });
    },
  },
  methods: {
    change() {
      this.$emit('change', this.value);
    },
  },
};
</script>

<style lang="sass">
$themeColor: #ff8b07 !default
$bgColor: #999 !default
@import 'vue-slider-component/lib/theme/material.scss'
</style>
<style scoped>
  .card-body{
    padding-bottom: 0.5rem;
  }
</style>
<style>
  /* .card.side-filter-card .card-header .btn{
    direction: rtl;
  } */
</style>
